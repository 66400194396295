import { t } from '@lingui/macro'
import { FinancialRecordLogTypeEnum } from '@/constants/assets'
import { FuturesTradeAccountTypeEnum, FuturesTransferAccountTypeEnum } from '@/constants/future/common'
import { getFollowAccountName } from '../copy-trading/common'

/**
 * 财务记录划转账户名称
 * @param accountName
 * @returns
 */
export const getFinancialRecordGroupName = accountName => {
  const replaceMap = {
    follower: t`features_copy_trading_settings_settings_form_index_lnc2lzgv_z`,
    taker: t`features_future_select_account_modal_index_coc1pyija8`,
  }
  return accountName?.replace(/follower|taker/g, matched => replaceMap[matched]) || ''
}

/** 跟单账户划转是展示名称 */
export const getTransferAccountName = (assetAccountType: FuturesTradeAccountTypeEnum | string, accountName) => {
  switch (assetAccountType) {
    case FuturesTradeAccountTypeEnum.takerAccount:
      return accountName ? t`features_future_select_account_modal_index_coc1pyija8` : ''
    case FuturesTradeAccountTypeEnum.followerAccount:
      return getFollowAccountName(accountName)
    case FuturesTradeAccountTypeEnum.futuresAccount:
    default:
      return accountName
  }
}

/**
 * 解决划转账户接口数据不一致问题
 * @param assetAccountType
 * @param data
 * @returns
 */
export const modifiedTransferAccountDataKey = (assetAccountType, data, transferAccountType) => {
  if (
    assetAccountType === FuturesTradeAccountTypeEnum.futuresAccount ||
    transferAccountType === FuturesTransferAccountTypeEnum.subAccount
  ) {
    return data
  }

  return data?.map(item => {
    const { accountId, accountName, ...rest } = item
    return {
      ...rest,
      groupId: accountId, // 修改 accountId 为 groupId
      groupName: getTransferAccountName(assetAccountType, accountName), // 修改 accountName 为 groupName
    }
  })
}

/** 获取带单/跟单子账户划转的主账户信息 */
export const getTransferDefaultMainAccount = data => {
  const { list, ...mainAccount } = data || {}
  const { accountId: groupId, accountName: groupName, ...rest } = mainAccount || ({} as any)

  return {
    groupId,
    groupName,
    isMain: true,
    ...rest,
  }
}
